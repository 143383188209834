import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import Booking from './pages/Booking';
import NotFound from './pages/NotFound';
import ThankYouPage from './pages/ThankYouPage';

import { Preloader } from './components';

export const ADMIN_URL = 'https://admin.sameday-repair.com/wp-json/wp/v2';

const App = () => {
  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/booking?per_page=100`);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Preloader />;

  return (
    <Routes>
      <Route path="/" element={<Navigate replace to="/miami" />} />

      {data?.map((page) => (
        <Route path={`/${page.slug}`} key={page.id}>
          <Route index element={<Booking settings={page.acf} />} />
          <Route path="thank-you" element={<ThankYouPage settings={page.acf} />} />
        </Route>
      ))}

      <Route path="/404" element={<NotFound />} />

      <Route path="*" element={<Navigate replace to="/404" />} />
    </Routes>
  );
};

export default App;
