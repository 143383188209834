import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useFetch from 'react-fetch-hook';
import moment from 'moment';

import { Box, Button, Modal, Typography } from '@mui/material';

import { Layout, Spinner, Accordion, Form } from '../components';

import { ADMIN_URL } from '../App';

const MAIL_URL = (id) => `https://admin.sameday-repair.com/wp-json/contact-form-7/v1/contact-forms/${id}/feedback`;

const modal_styles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: 600,
  width: '100%',
  backgroundColor: '#fff',
  boxShadow: 24,
  p: 4,
};

const Booking = ({ settings }) => {
  const { isLoading, data } = useFetch(`${ADMIN_URL}/general_settings?slug=booking-settings`);

  const city = window.location.pathname.split('/')[1];
  const source = window.location.search.split('?source=')[1] || 'WS';

  const navigate = useNavigate();

  const [isPending, setRequestStatus] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [bookingData, setBookingData] = useState({
    bookingDate: '',
    bookingTime: '',
    bookingDetails: {},
  });
  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState();
  const [stepTitle, setStepTitle] = useState('Choose a service');

  const reset = () =>
    setBookingData({
      bookingDate: '',
      bookingTime: '',
      bookingDetails: {},
    });

  const handleClose = () => setOpen(false);

  const onSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('brand', bookingData.bookingDetails.brand_name);
    formData.append('date', moment(bookingData.bookingDate).format('LL'));
    formData.append('time', bookingData.bookingTime);
    formData.append('city', city.charAt(0).toUpperCase() + city.slice(1));
    formData.append('job_source', source.charAt(0).toUpperCase() + source.slice(1));
    formData.append('image', bookingData.image);

    Object.entries(bookingData.bookingDetails).map((el) => formData.append(el[0], el[1]));
    formData.append('_wpcf7_unit_tag', true);

    try {
      setRequestStatus(true);
      const response = await axios.post(MAIL_URL(settings?.booking_form_id), formData);

      if (response.status === 200) {
        setRequestStatus(false);
        event.target.reset();
        setActiveStep(0);
        navigate('./thank-you');
      }
    } catch (error) {
      setOpen(true);
      setModalText('Ooops! Something went wrong! Please, try again!');
      setRequestStatus(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (activeStep === 0) reset();

    switch (activeStep) {
      case 1:
        setStepTitle('Choose a brand');
        break;
      case 2:
        setStepTitle('Upload photo (not required)');
        break;
      case 3:
        setStepTitle('Choose a day');
        break;
      case 4:
        setStepTitle('Choose a time');
        break;
      case 5:
        setStepTitle('Details');
        break;
      case 6:
        setStepTitle('Order Summary');
        break;
      default:
        setStepTitle('Choose a service');
        break;
    }
  }, [activeStep]);

  if (isLoading) return <Spinner />;

  return (
    <Layout id="booking-page" step={activeStep} phone={settings.phone_number}>
      <section className="booking__wrapper">
        <div className="container">
          <div className="booking__inner">
            <div className="faq">
              <h1>{stepTitle}</h1>

              <div>
                {data[0].acf.faq.map((el, index) => (
                  <Accordion title={el.question} key={index}>
                    {el.answer}
                  </Accordion>
                ))}

                <div className="logo" />
              </div>
            </div>

            <div className="form__wrapper">
              <Form
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                stepTitle={stepTitle}
                bookingData={bookingData}
                setBookingData={setBookingData}
                onSubmit={onSubmit}
                isPending={isPending}
                settings={settings}
                services={data[0]?.acf.services}
                brands={data[0]?.acf.brands}
              />
            </div>
          </div>
        </div>
      </section>

      {modalText && (
        <Modal open={open} onClose={handleClose}>
          <Box sx={modal_styles}>
            <Typography variant="h4" textAlign="center">
              {modalText}
            </Typography>

            <Button
              variant="contained"
              color="error"
              size="small"
              sx={{ display: 'block', margin: '25px auto 0' }}
              onClick={handleClose}
            >
              Got It
            </Button>
          </Box>
        </Modal>
      )}
    </Layout>
  );
};

export default Booking;
