import { NavLink } from 'react-router-dom';

import './thanks.scss';

const ThankYou = ({ data, city = window.location.pathname.split('/')[1] }) => (
  <div className="thanks-wrapper">
    <div className="container">
      <div className="section-title">
        <h2>{data?.title}</h2>
      </div>

      <p className="subtitle">{data?.subtitle}</p>
      <p>{data?.text}</p>

      <NavLink to={`//sameday-repair.com/${city}`} className="red-button">
        Back To Home Page
      </NavLink>
    </div>
  </div>
);

export default ThankYou;
