import { useState, useEffect } from 'react';
import useFetch from 'react-fetch-hook';
import { toast } from 'react-toastify';

import { Layout, Spinner, ThankYou } from '../components';

const ADMIN_URL = 'https://admin.sameday-repair.com/wp-json/wp/v2';

const ThankYouPage = ({ settings }) => {
  const [pageData, setPageData] = useState();

  const { isLoading, data, error } = useFetch(`${ADMIN_URL}/general_settings?slug=settings`);

  useEffect(() => {
    if (data) setPageData(data[0].acf);
  }, [data]);

  useEffect(() => {
    if (error) toast.error('Data loading error. Please reload the page!');
  }, [error]);

  if (isLoading) return <Spinner />;

  return (
    <Layout pageId="thank-you-page" phone={settings.phone_number}>
      <section>
        <ThankYou data={pageData?.thank_you_page} />
      </section>
    </Layout>
  );
};

export default ThankYouPage;
